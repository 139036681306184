import { useEffect } from "react"

import Button from "../../base/Button"
import Stack from "../../base/Stack"
import Switch from "../../base/Switch"
import Typography from "../../base/Typography"
import Card, { CardContent } from "../../base/Card"
import { useForm, useMutation } from "../../hooks"

export default function RoomCard({ openQR, room }) {
  const to = `/rooms/${room.code}`
  const userCount = room.users?.length
  const mutation = useMutation("rooms", room.code)
  const { form: { values }, registerField } = useForm({ open: room.open })

  useEffect(() => {
    if (room.open !== values.open) mutation.mutate(values)
  }, [values.open])

  const onOpenClick = () => openQR(room)
  return (
    <Card sx={{ width: 300 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={10}>
          <Stack>
            <Typography variant="body1">{room.name}</Typography>
            <Typography variant="caption">Created: {room.created_at}</Typography>
            <Typography variant="caption">Users: {userCount}</Typography>
            <form>
              <Switch {...registerField("open")} label="Open" />
            </form>
          </Stack>
        </Stack>
        <Button to={to}>Join Room</Button>
        <Button onClick={onOpenClick}>Show QR Code</Button>
      </CardContent>
    </Card>
  )
}
