import { useRef, useLayoutEffect } from 'react'
import useStayScrolled from 'react-stay-scrolled'

import ConversationItem from './ConversationItem'
import MessageInput from './MessageInput'
import Stack from '../../base/Stack'
import Typography from "../../base/Typography"

import styles from "./Conversation.module.scss"

export default function Conversation({ messages, onSend, open }) {
  const messagesRef = useRef();
  const { stayScrolled } = useStayScrolled(messagesRef, { inaccuracy: 60 });

  useLayoutEffect(() => {
    stayScrolled()
  }, [messages])

  return (
    <>
      <div ref={messagesRef} className={styles.messagesContainer}>
        <Stack direction="column">
          <Typography align="center" variant="body1">Add a new message. To request a response from the AI agent, include '@ai' in your message.</Typography>
          {messages.map((message, index) => {
            const prevMessage = index > 0 ? messages[index - 1] : null
            const nextMessage = messages[index + 1]
            return <ConversationItem key={message.id} message={message} prevMessage={prevMessage} nextMessage={nextMessage} />
          })}
        </Stack>
      </div>
      {open && <MessageInput onSend={onSend} />}
    </>
  )
}
