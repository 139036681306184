import React from "react"
import { TextField } from "@mui/material"
import { get } from 'lodash'

export default function TextInput({
  form,
  fullWidth,
  label,
  multiline,
  name,
  onBlur,
  onChange,
  required,
  type,
  value,
}) {
  const id = name
  const fieldError = get(form, `errors[${name}]`)
  const fieldTouched = get(form, `touched[${name}]`)
  const hasError = fieldTouched && !!fieldError
  const helperText = hasError ? fieldError : " "

  return (
    <TextField
      id={id}
      error={hasError}
      fullWidth={fullWidth}
      helperText={helperText}
      label={label}
      maxRows={4}
      multiline={multiline}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      type={type}
      value={value}
      variant="outlined"
    />
  )
}
