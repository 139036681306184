import { useEffect, useState } from "react"
import { orderBy } from "lodash"

import AddFab from "../../base/AddFab"
import Button from "../../base/Button"
import RoomCard from "../../components/RoomCard"
import Grid from "../../base/Grid"
import Stack from "../../base/Stack"
import Select from "../../base/Select"
import TextInput from "../../base/TextInput"
import Typography from "../../base/Typography"
import Modal from "../../base/Modal"
import { CardContent } from "../../base/Card"
import QRCode from "../../base/QRCode"
import useForm from "../../hooks/useForm"
import useMutation from "../../hooks/useMutation"
import useQuery from "../../hooks/useQuery"

export default function Home() {
  const [formOpen, setFormOpen] = useState(false)
  const [openRoom, setOpenRoom] = useState(null)
  const { data } = useQuery('rooms')
  const { data: chatAiModels } = useQuery('chat_ai_models')
  const mutation = useMutation("rooms")

  const rooms = data
  const orderedRooms = Array.isArray(rooms) ? orderBy(rooms, ['created_at'], ['desc']) : []

  const aiModelOptions = () => {
    if (Array.isArray(chatAiModels))
      return chatAiModels.map(({ id, model_name }) => ({ label: model_name, value: id }))
    
    return []
  }

  const { form, registerField, resetForm } = useForm({ name: "" })
  const openForm = () => setFormOpen(true)
  const closeForm = () => setFormOpen(false)
  const closeQR = () => setOpenRoom(false)

  useEffect(() => {
    if (!formOpen) resetForm()
  }, [formOpen])

  const handleAdd = (e) => {
    e.preventDefault()
    if (formOpen) {
      mutation.mutate(form.values)
      setFormOpen(false)
    } else {
      setFormOpen(true)
    }
  }

  const qrModal = () => {
    const joinUrl = `${window.location.origin}/join/${openRoom?.code}`
    return (
      <Modal open={!!openRoom} handleClose={closeQR}>
        <CardContent>
          <Stack spacing={4}>
            <Typography variant="h2">{openRoom?.name}</Typography>
            <QRCode url={joinUrl} />
          </Stack>
        </CardContent>
      </Modal>
    )
  }

  const formModal = () => (
    <Modal open={formOpen} handleClose={closeForm}>
      <CardContent>
        <form onSubmit={handleAdd} noValidate>
          <Stack spacing={4}>
            <TextInput label="Name" {...registerField("name", { required: true })} />
            <Select label="Ai Agent" {...registerField("ai_model_id", { required: true })} options={aiModelOptions()} />
            <Button type="submit">Create New Room</Button>
          </Stack>
        </form>
      </CardContent>
    </Modal>
  )
  
  return (
    <Stack spacing={3} alignItems="center">
      <Typography variant="h1">Home</Typography>
      <Grid columns="repeat(auto-fill, 300px)" spacing={4} padded justifyContent="center">
        {orderedRooms.map(room => (<RoomCard key={room.id} room={room} openQR={setOpenRoom} />))}
      </Grid>
      {qrModal()}
      {formModal()}
      <AddFab onClick={openForm}>Add New Room</AddFab>
    </Stack>
  )
}
