import { useNavigate } from "react-router-dom"

import Button from "../../base/Button"
import Stack from "../../base/Stack"
import TextInput from "../../base/TextInput"
import Typography from "../../base/Typography"
import { useForm, useMutation } from "../../hooks"

export default function Login() {
  const navigate = useNavigate();
  const { form: { values }, registerField, validateForm } = useForm({ email: "", password: "" })
  const mutation = useMutation("users/login")
  const { error } = mutation

  const navigateToRoom = (data) => {
    if (data?.token) {
      localStorage.setItem("token", data?.token)
      navigate("/")
  }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (!validateForm()) return false
    mutation.mutate(values, {
      onSuccess: navigateToRoom,
    })
  }

  function renderError() {
    const message = error?.message
    return <Typography color="red" variant="body1">{message}</Typography>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={2}>
        <TextInput label="Email" {...registerField('email', { required: true })} />
        <TextInput label="Password" type="password" {...registerField('password', { required: true })} />
        {renderError()}
        <Button onClick={handleSubmit} type="submit">Submit</Button>
      </Stack>
    </form>
  );
}
