import Button from "../../base/Button"
import Stack from "../../base/Stack"
import TextInput from "../../base/TextInput"
import useForm from "../../hooks/useForm"

import styles from "./Conversation.module.scss"

export default function MessageInput({ onSend }) {
  const { form: { values }, registerField, resetForm } = useForm({ message: "" })

  const handleSend = (e) => {
    e.preventDefault()
    onSend(values)
    resetForm()
  }

  return (
    <form onSubmit={handleSend}>
      <div className={styles.composeContainer}>
        <Stack direction="row" spacing={4} alignItems="center">
          <div className={styles.composeInput}>
            <TextInput fullWidth multiline {...registerField("message")} />
          </div>
          <div className={styles.composeButton}>
            <Button type="submit">Send</Button>
          </div>
        </Stack>
      </div>
    </form>
  )
}
