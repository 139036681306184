import { Routes, Route } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Join from './pages/Join'
import Room from './pages/Room'
import Home from './pages/Home'
import Login from './pages/Login'
import Template from "./base/Template"

import variables from './variables.scss'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: variables.darkBlue,
    },
    secondary: {
      main: variables.teal,
    },
    error: {
      main: variables.darkRed,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: variables.white,
        }
      }
    }
  },
});

export const queryClient = new QueryClient()

function withTemplate(wrappedComponent, templateProps = {}) {
  return (
    <Template {...templateProps}>
      {wrappedComponent}
    </Template>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/login" element={withTemplate(<Login />, { centerVertical: true })} />
          <Route path="/join/:code?" element={withTemplate(<Join />, { centerVertical: true })} />
          <Route path="rooms/:code" element={withTemplate(<Room />)} />
          <Route path="/" element={withTemplate(<Home />)} />
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
