import React from "react"
import ReactQRCode from "react-qr-code"

import Button from "../Button"
import Stack from "../Stack"

export default function QRCode({ url }) {
  const copyJoinUrl = () => {
    navigator.clipboard.writeText(url)
  }

  return (
    <Stack spacing={3} alignItems="center">
      <ReactQRCode value={url} />
      <Button onClick={copyJoinUrl}>Copy Link</Button>
    </Stack>
  )
}
