import React from "react"
import { Link } from "react-router-dom"
import { Button as MuiButton } from "@mui/material"

function Button({ children, onClick, to, type }) {
  if (to) return (
    <MuiButton component={Link} to={to}>
      {children}
    </MuiButton>
  )

  return (
    <MuiButton onClick={onClick} type={type}>{children}</MuiButton>
  )
}

export default Button
