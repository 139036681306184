import React from "react"
import { InputLabel, FormControl, FormHelperText, Select as MuiSelect, MenuItem } from "@mui/material"
import { get } from 'lodash'

export default function Select({
  form,
  fullWidth,
  label,
  name,
  onBlur,
  onChange,
  options,
  required,
  value,
}) {
  const id = name
  const fieldError = get(form, `errors[${name}]`)
  const fieldTouched = get(form, `touched[${name}]`)
  const hasError = fieldTouched && !!fieldError
  const helperText = hasError ? fieldError : " "

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" error={hasError} required={required}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map(option => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
