import React from "react"
import { Modal as MuiModal } from "@mui/material"

import Card from "../Card"
import styles from './Modal.module.scss'

function Modal({ children, open, handleClose }) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card classes={{ root: styles.modal }}>
        {children}
      </Card>
    </MuiModal>
  )
}

export default Modal
