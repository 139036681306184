import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { sortBy, uniqBy } from "lodash"

import Conversation from "../../components/Conversation"
import Stack from '../../base/Stack'
import Typography from "../../base/Typography"
import useQuery from "../../hooks/useQuery"

import styles from "./Room.module.scss"

function useSubscription(roomCode, shouldConnect, initialData) {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    if (data.length === 0 && initialData) {
      setData(initialData)
    }
  }, [initialData, data.length])

  const connectSocket = () => {
    const token = localStorage.getItem("token")
    const baseUrl = window.location.host.includes("localhost") ? "ws://localhost:8000" : `wss://${window.location.host}`
    const url = `${baseUrl}/ws/chat/${roomCode}/?token=${token}`
    const chatSocket = new WebSocket(url)
    setSocket(chatSocket)
  }

  const insertData = (data, newItem) => uniqBy([newItem, ...data], 'id')

  useEffect(() => {
    if (!socket && shouldConnect) {
      connectSocket()
      return () => socket?.close()
    }
    if (!shouldConnect) socket?.close()
  }, [socket, shouldConnect])

  useEffect(() => {
    if (socket) {
      socket.onmessage = function(e) {
        const newData = JSON.parse(e.data)
        setData(d => insertData(d, newData))
      }
  
      socket.onerror = function(e) {
        // not sure if this works, but leaving it for now
        setError('There was an error trying to connect to this room.')
        console.error(e)
      } 
    }
  }, [socket])

  function send(object) {
    socket.send(JSON.stringify(object))
  }

  return { data, error, send }
}

function Room(props) {
  const { code } = useParams()
  const { data } = useQuery(`rooms/${code}`)

  const room = data || {}
  const { open } = room
  const { data: messages, error, send } = useSubscription(code, open, room?.messages)
  const sortedMessages = sortBy(messages, ['timestamp'])

  const handleSend = ({ message }) => {
    send({ 'message': message })
  }

  const renderError = () => {
    if (!error) return null
    return (
      <div className={styles.errorBanner}>
        <Typography align="center" variant="body1" color="white">{error}</Typography>
      </div>
    )
  }

  return (
    <Stack direction="column" spacing={1} height="100vh">
      <div style={{ boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)" }}>
        <Typography variant="h2">{room.name}</Typography>
        {renderError()}
      </div> 
      <Conversation open={open} messages={sortedMessages} onSend={handleSend} />
    </Stack>
  )
}

export default Room
