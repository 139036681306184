import React from "react"
import classNames from "classnames"

import styles from "./Grid.module.scss"

export default function Grid({ children, columns, justifyContent, padded, spacing, rows }) {
  const classes = classNames(styles.grid, {
    [styles.gap3]: spacing === 3,
    [styles.gap4]: spacing === 4,
    [styles.padded]: padded,
    [styles.justifyCenter]: justifyContent === 'center',
  })

  return (
    <div className={classes} style={{ 'grid-template-columns': columns, 'grid-template-rows': rows }}>
      {children}
    </div>
  )
}
