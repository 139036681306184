import React from "react"
import { Stack as MuiStack } from "@mui/material"

export default function Stack({
  alignItems,
  children,
  direction,
  flexWrap,
  height = "auto",
  justifyContent,
  spacing
}) {
  return (
    <MuiStack
      alignItems={alignItems}
      direction={direction}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      spacing={spacing}
      sx={{ width: 1, height }}
      useFlexGap
    >
      {children}
    </MuiStack>
  )
}
