import React from "react"
import { FormControlLabel, Switch as MuiSwitch } from "@mui/material"

export default function Switch({
  form,
  label,
  name,
  onBlur,
  onChange,
  value,
 }) {
  const handleChange = e => {
    onChange(e.target.checked)
  }

  return (
    <FormControlLabel
      control={(
        <MuiSwitch
          name={name}
          checked={value}
          onChange={handleChange}
        />
      )}
      label={label}
    />
  )
}
