import classNames from 'classnames'

import styles from './Template.module.scss'

function Template({ centerVertical, children }) {
  const classes = classNames(styles.template, {
    [styles.centerVertical]: centerVertical,
  })
  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Template
