import { useState } from 'react'
import { get, isEmpty } from "lodash"

export default function useForm(initialValues = {}) {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [fieldValidations, setFieldValidations] = useState({})

  const form = {
    errors,
    touched,
    values,
  }

  function resetForm() {
    setValues(initialValues)
  }

  function setFieldValue(name, newValue) {
    validateField(name, newValue)
    setValues(existing => Object.assign(existing, { [name]: newValue }))
  }

  function validateForm() {
    const fieldNames = Object.keys(fieldValidations)
    const newTouched = fieldNames.reduce((acc, name) => {
      return { ...acc, [name]: true }
    }, {})
    setTouched(newTouched)
    const newErrors = fieldNames.reduce((acc, name) => {
      const error = checkFieldError(name) || {}
      return { ...acc, ...error }
    }, {})
    setErrors(newErrors)
    return isEmpty(newErrors)
  }

  function checkFieldError(name, value = null) {
    const validations = get(fieldValidations, name)
    const fieldValue = value || get(values, name)
    if (validations?.required && !fieldValue) {
      return { [name]: "Required" }
    }
  }

  function validateField(name, value = null) {
    const fieldValue = value || get(values, name)
    const newError = checkFieldError(name, fieldValue)
    if (newError) {
      setErrors({ ...errors, ...newError })
    } else {
      const { [name]: _, ...newErrors } = errors
      setErrors(newErrors)
    }
  }

  function registerField(name, validations = {}) {
    if (!get(fieldValidations, name)) {
      setFieldValidations({ ...fieldValidations, [name]: validations })
    }

    function setValue(eventOrValue) {
      const newValue = eventOrValue?.target ? eventOrValue.target.value : eventOrValue
      setFieldValue(name, newValue)
    }
    const setFieldTouched = () => {
      validateField(name)
      if (!get(touched, name)) setTouched({ ...touched, [name]: true })
    }

    return {
      name,
      onBlur: setFieldTouched,
      onChange: setValue,
      required: validations.required,
      value: values[name] || "",
      form,
    }
  }

  return { form, registerField, validateForm, resetForm }
}
