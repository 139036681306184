import classNames from 'classnames'
import Markdown from 'react-markdown'

import Stack from "../../base/Stack"
import Typography from "../../base/Typography"

import styles from "./Conversation.module.scss"

export default function ConversationItem({ message, nextMessage, prevMessage  }) {
  const sender = message.sender || message.chat_ai_model
  const firstOfGroup = message.sender !== prevMessage?.sender
  const lastOfGroup = message.sender !== nextMessage?.sender

  const classes = classNames(styles.messageItem, {
    [styles.left]: message.chat_ai_model,
    [styles.right]: !message.chat_ai_model,
    [styles.self]: message.is_self,
    [styles.firstOfGroup]: firstOfGroup,
    [styles.lastOfGroup]: lastOfGroup,
  })
  const color = "black"
  const components = { 
    p({ children, ...props }) {
    return <Typography color={color} {...props}>{children}</Typography>
  }}

  return (
    <div className={classes}>
      {firstOfGroup && <Typography color={"color"} variant="caption">{sender}</Typography>}
      <Stack direction="column" spacing={1}>
        <Markdown components={components}>
          {message.content}
        </Markdown>
      </Stack>
    </div>
  )
}
