import React from "react"
import { Fab } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

function AddFab({ children, onClick }) {
  const variant = children ? "extended" : "circular"
  return (
    <Fab color="primary" aria-label="add" onClick={onClick} variant={variant}>
      <AddIcon />
      {children}
    </Fab>

  )
}

export default AddFab
