import { useQuery as useReactQuery } from '@tanstack/react-query'
import { flatten, join, values } from 'lodash'

const baseUrl = "/api"

export default function useQuery(key, id = null) {
  const url = `${baseUrl}/${key}/`

  return useReactQuery({
    queryKey: [key],
    queryFn: async () => {
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${localStorage.getItem("token")}`,
          }
        }
      )
      const json = await response.json()
      if (response.status === 401) {
        return window.location = "/login"
      }
      if (!response.ok) {
        const errorMessage = join(flatten(values(json)))
        throw new Error(errorMessage)
      }
      
      return json
    }
  })
}
