import { useMutation as useReactMutation } from '@tanstack/react-query'

import { queryClient } from '../App'
import { flatten, join, values } from 'lodash'

const baseUrl = "/api"

export default function useMutation(key, id = null) {
  const url = id ? `${baseUrl}/${key}/${id}/` : `${baseUrl}/${key}/`
  const method = id ? "PATCH" : "POST"

  return useReactMutation({
    mutationFn: async (data) => {
      const token = localStorage.getItem("token")
      const headers = { "Content-Type": "application/json" }
      if (token) {
        headers.Authorization = `Token ${localStorage.getItem("token")}`
      }
      const response = await fetch(
        url,
        { method, headers, body: JSON.stringify(data) }
      )
      const json = await response.json()
      if (!response.ok) {
        const errorMessage = join(flatten(values(json)))
        throw new Error(errorMessage)
      }
      
      return json
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [key] })
    },
    onError: (error) => console.log(error)
  })
}
