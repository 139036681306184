import { useNavigate, useParams } from "react-router-dom";

import Button from "../../base/Button"
import Stack from "../../base/Stack"
import TextInput from "../../base/TextInput"
import Typography from "../../base/Typography"
import { useForm, useMutation } from "../../hooks"

export default function Join() {
  const { code } = useParams()
  const navigate = useNavigate();
  const { form: { values }, registerField, validateForm } = useForm(
    { first_name: "", last_name: "", code: code || "" }
  )
  const mutation = useMutation("users")
  const { error } = mutation

  const navigateToRoom = (data) => {
    if (data?.token) {
      localStorage.setItem("token", data?.token)
      navigate(`/rooms/${values.code}`)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateForm()) return false
    mutation.mutate(values, {
      onSuccess: navigateToRoom,
    })
  }

  function renderError() {
    const message = error?.message
    return <Typography color="red" variant="body1">{message}</Typography>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={2}>
        <TextInput label="Room Code" {...registerField('code', { required: true })} />
        <TextInput label="First Name" {...registerField('first_name', { required: true })} />
        <TextInput label="Last Name" {...registerField('last_name', { required: true })} />
        {renderError()}
        <Button onClick={handleSubmit} type="submit">Submit</Button>
      </Stack>
    </form>
  );
}
