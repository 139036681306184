import React from "react"
import classNames from "classnames"
import { Typography as MuiTypography } from "@mui/material"

import styles from "./Typography.module.scss"

export default function Typography({ align, children, color, variant }) {
  const root = classNames(styles.black, {
    [styles.red]: color === 'red',
    [styles.white]: color === 'white',
  })
  return (
    <MuiTypography align={align} classes={{ root }} variant={variant}>{children}</MuiTypography>
  )
}
